export default [
  {
    path: '',
    component: resolve => require(['@/views/PublicSite/Home/Index.vue'], resolve),
    meta: {
      title: 'Meghpakhi Academy of Music'
    }
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/'
  },
  {
    path: '/about',
    name: 'about',
    component: resolve => require(['@/views/PublicSite/About.vue'], resolve),
    meta: {
      title: 'About'
    }
  },
  {
    path: '/discography',
    component: resolve => require(['@/views/PublicSite/Discography/Index.vue'], resolve),
    children: [
      {
        path: '',
        name: 'discography',
        component: resolve => require(['@/views/PublicSite/Discography/AllSongs.vue'], resolve),
        meta: {
          title: 'Discography'
        }
      },
      {
        path: ':song_id',
        name: 'song',
        props: true,
        component: resolve => require(['@/views/PublicSite/Discography/SingleSong.vue'], resolve),
        meta: {
          title: 'Song'
        }
      }
    ]
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: resolve => require(['@/views/PublicSite/Gallery/Index.vue'], resolve),
    meta: {
      title: 'Gallery'
    }
  },
  {
    path: '/media',
    name: 'media',
    component: resolve => require(['@/views/PublicSite/Media.vue'], resolve),
    meta: {
      title: 'Media'
    }
  },
  {
    path: '/albums',
    name: 'albums',
    component: resolve => require(['@/views/PublicSite/Albums.vue'], resolve),
    meta: {
      title: 'Albums'
    }
  },
  {
    path: '/literature',
    component: resolve => require(['@/views/PublicSite/Literature/Index.vue'], resolve),
    children: [
      {
        path: '',
        name: 'literature',
        component: resolve => require(['@/views/PublicSite/Literature/AllLiteratures.vue'], resolve),
        meta: {
          title: 'Literature'
        }
      },
      {
        path: ':write_id',
        name: 'write',
        props: true,
        component: resolve => require(['@/views/PublicSite/Literature/SingleLiterature.vue'], resolve),
        meta: {
          title: 'Write'
        }
      }
    ]
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: resolve => require(['@/views/PublicSite/Contact.vue'], resolve),
    meta: {
      title: 'Contact Us'
    }
  },
  {
    path: '/academy',
    name: 'academy',
    component: resolve => require(['@/views/PublicSite/Academy.vue'], resolve),
    meta: {
      title: 'Meghpakhi Academy of Music'
    }
  },
  {
    path: '*',
    component: resolve => require(['@/views/misc/PageNotFound'], resolve)
  }
]
